<template>
  <div class="about">
    <div class="container mx-auto">
      <div class="flex flex-wrap">
        <div class="w-full bg-white p-4">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    //
  },

  mounted () {
    //
  },

  methods: {
    //
  }
}
</script>
